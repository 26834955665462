﻿import jQuery from 'jquery';

function initAnimations() {
    initSlideToggleHandlers();
    showMobileSupportWarning();
}

function initSlideToggleHandlers() {
    var slideToggleTriggers = document.getElementsByClassName('slide-toggle-trigger');
    for (var i = 0; i < slideToggleTriggers.length; i++) {
        const slideToggleTrigger = slideToggleTriggers.item(i);
        const toggledClass = slideToggleTrigger.dataset.toggledClass;
        if (!toggledClass) {
            return;
        }
        
        slideToggleTrigger.addEventListener('click', function () {
            jQuery(`.${toggledClass}`).slideToggle();
        });
    }
}

function showMobileSupportWarning() {
    jQuery('iframe').on('load', function () {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            jQuery('.mobile-support-warning').toggle();
        }
    });    
}

export { initAnimations };