﻿function localizeDatetimeTitles() {
	var itemsToLocalize = document.getElementsByClassName('localize-datetime-title');
	for (let i = 0; i < itemsToLocalize.length; i++) {
		var itemToLocalize = itemsToLocalize[i];
		var utcDateTimeString = itemToLocalize.title;
		var utcDateTime = new Date(utcDateTimeString);
		var localDateTimeString = utcDateTime.toLocaleString();
		itemToLocalize.title = localDateTimeString;
    }
}

export { localizeDatetimeTitles };