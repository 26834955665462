﻿import Editor from '@toast-ui/editor'

function initSummaries() {
    initSummaryEditors();
    initSummaryViewers();
}

function initSummaryEditors() {
    var viewers = document.getElementsByClassName('summary-editor');
    for (var i = 0; i < viewers.length; i++) {
        const summaryNode = viewers.item(i);
        const initialValueInputId = summaryNode.dataset.initialValueId;
        const summaryInitialValue = document.querySelector(`input[id=${initialValueInputId}]`).value;
        const editor = new Editor({
            el: summaryNode,
            height: '300px',
            initialEditType: 'markdown',
            previewStyle: 'tab',
            hideModeSwitch: true,
            autofocus: false,
            initialValue: summaryInitialValue,
            usageStatistics: false,
        });

        const targetInput = document.getElementById(summaryNode.dataset.targetInputId);
        if (!targetInput) {
            return;
        }

        targetInput.value = editor.getMarkdown();
        editor.on('change', function () {
            targetInput.value = editor.getMarkdown();
        });
    }
}

function initSummaryViewers() {
    var viewers = document.getElementsByClassName('summary-viewer');
    for (var i = 0; i < viewers.length; i++) {
        const summaryNode = viewers.item(i);
        const initialValueInputId = summaryNode.dataset.initialValueId;
        const summaryInitialValue = document.querySelector(`input[id=${initialValueInputId}]`).value;
        const viewer = new Editor.factory({
            el: summaryNode,
            viewer: true,
            initialValue: summaryInitialValue,
            usageStatistics: false,
        });
    }
}

export { initSummaries };