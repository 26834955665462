﻿// Make jQuery globally available with jQuery or $ variables.
import jQuery from 'expose-loader?exposes=$,jQuery!jquery';

// Not sure why we have this dependency.
import 'jquery-color';

// Dropotron is used for the navigation menu.
import dropotron from './jquery.dropotron.min.js';

// Skel unmaintained since 2017 and deprecated since 2018. Should remove at some point.
import skel from 'expose-loader?exposes=skel!./skel.min.js';
import skelViewport from './skel-viewport.min.js';

// util.js adds 4 jQuery extensions that are relied on by the theme/template.
import util from './util.js';

// main.js is theme/template boilerplate, code highlighting, and search. Should split them out.
import main from './main.js';

import { initAnimations } from './animations';
import { initSummaries } from './summaries';
import { initCarousels } from './carousels';
import { localizeDatetimeTitles } from './localization';

initAnimations();
initSummaries();
initCarousels();
localizeDatetimeTitles();